import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > label {
    padding-bottom: 1.5px;
    font-size: 0.95rem;
  }
`

export const Element = styled.textarea`
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 2px;
  vertical-align: middle;
  padding: 5px 7.5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;

  &:focus {
    border-color: var(--blue);
  }

  &.error {
    border-color: var(--error);
  }
`
