import React from "react"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons"

const Checkbox = ({ note = "", req = true, name, update, data }) => {
  return (
    <Container>
      <div>
        {data.map((item, index) => {
          return (
            <label key={index} onClick={e => update(name, item.value)}>
              <FontAwesomeIcon icon={item.checked ? faCheckSquare : faSquare} />
              <span>{item.label}</span>
            </label>
          )
        })}
      </div>
      <small className={note ? "" : "hidden"}>{note}</small>
    </Container>
  )
}

export default Checkbox
