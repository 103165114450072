import React from "react"
import { Router } from "@reach/router"

import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"

import Manage from "../components/charity/Manage"

const Empty = () => <div></div>

const Charity = () => {
  return (
    <Layout>
      <Helmet />
      <Router>
        <Manage path="/charity/:id" />
        <Empty exact path="/charity" />
      </Router>
    </Layout>
  )
}

export default Charity
