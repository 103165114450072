import styled from "styled-components"
import {
  Container as BaseContainer,
  Grid2 as BaseGrid,
} from "../../../elements/Layout/styled"

export const Container = styled(BaseContainer)``

export const Grid = styled(BaseGrid)``

export const Logo = styled.div`
  & > img {
    width: 25%;
  }
`
