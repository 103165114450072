import React, { useState, useEffect, useRef  } from "react"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"

import { API_URL } from "../../../utils/helpers"
import { getUser } from "../../../utils/auth"

import Alert from "../../Alert/index"

import Nav from "../../Nav/index"

import Input from "../../form/Input"
import InputFile from "../../form/InputFile"
import Checkbox from "../../form/Checkbox"
import Textarea from "../../form/Textarea"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"

import { Container, Grid, Logo } from "./styled"

const Manage = ({ id }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const initRef = useRef(false)

  useEffect(() => {
    if (initRef.current) return
    initRef.current = true
    if (getUser()) {
      init(id, getUser().token)
    }
  })

  const init = async (id, token) => {
    const url = new URL(`${API_URL}/`)
    const params = { 
      admin: "1",
      f: "adminCharityInit",
      id, token,
      x: uuidv4()
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setNames(json)
        setAlert({})
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleUpdate = (name, value) => {
    if (name === "visible" || name === "display") {
      setNames(names => ({ ...names, [name]: !names[name] }))
    } else if (name === "file") {
      handleFileSelected(value)
    } else {
      setNames(names => ({ ...names, [name]: value }))
    }
  }

  const handleFileSelected = file => {
    const newImage = {
      id: "new",
      url: URL.createObjectURL(file),
    }
    setNames(names => ({ ...names, file, newLogo: newImage.url }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminCharity&admin=1`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "visible" || key === "display") {
        formData.append(key, value ? "1" : "0")
      } else {
        formData.append(key, value)
      }
    })
    formData.append("token", getUser().token || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setMsg({})
        navigate(json.next)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <>
          <Nav
            sections={[
              { name: "Admin Home", to: "/" },
              { name: "", to: "" },
              { name: "Charities", to: "/charities" },
              { name: "", to: "" },
              { name: `Charity ${id}`, to: "" },
            ]}
            title="Manage Charity Details / Visibility / Logo"
          />

          <p className="no-top-margin">
            Use this section to view a submitted charity’s details or manage
            info, visibility or logo in the database.
          </p>

          <Grid>
            <div>
              <h3>Charity Details</h3>

              <table>
                <tbody>
                  <tr>
                    <th className="right">Date:</th>
                    <td>{names.date}</td>
                  </tr>
                  <tr>
                    <th className="right">Charity Name:</th>
                    <td>{names.name}</td>
                  </tr>
                  <tr>
                    <th className="right">Code:</th>
                    <td>{names.code}</td>
                  </tr>
                  <tr>
                    <th className="right">Visible:</th>
                    <td>{names.visible ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th className="right">Mission:</th>
                    <td>{names.mission}</td>
                  </tr>
                  <tr>
                    <th className="right">Children Supported:</th>
                    <td>{names.children}</td>
                  </tr>
                  <tr>
                    <th className="right">Logo Uploaded:</th>
                    <td className="center logo">
                      <img src={names.logo} alt={names.logo_alt} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3>Contact Info</h3>

              <table>
                <tbody>
                  <tr>
                    <th className="right">Display Address:</th>
                    <td>{names.display ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th className="right">Address:</th>
                    <td>
                      {`${names.address1}`}
                      <br />
                      {`${names.address2}`}
                    </td>
                  </tr>
                  <tr>
                    <th className="right">Contact:</th>
                    <td>{`${names.cfname} ${names.clname}`}</td>
                  </tr>
                  <tr>
                    <th className="right">Email:</th>
                    <td>{names.email}</td>
                  </tr>
                  <tr>
                    <th className="right">Finance Contact:</th>
                    <td>{`${names.cfname2} ${names.clname2}`}</td>
                  </tr>
                  <tr>
                    <th className="right">Email:</th>
                    <td>{names.email2}</td>
                  </tr>
                  <tr>
                    <th className="right">Phone:</th>
                    <td>{names.phone}</td>
                  </tr>
                  <tr>
                    <th className="right">Fax:</th>
                    <td>{names.fax}</td>
                  </tr>
                  <tr>
                    <th className="right">Web:</th>
                    <td>{names.web}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <form onSubmit={e => handleSubmit(e)}>
            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Charity Name"
                  req={true}
                  name="name"
                  value={names.name}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Charity Code"
                  req={true}
                  name="code"
                  value={names.code}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <div>
              <Checkbox
                name="visible"
                note="Check above to indicate whether or not this charity is visible on donate now page. Please note: any donations recorded to this charity (even if visibility is off) will still be displayed on the standings page."
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label: "Check to have charity visible.",
                  },
                ].map(item => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.visible,
                  }
                })}
              />
            </div>

            <p className="line" />

            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Address Line 1"
                  req={true}
                  name="address1"
                  value={names.address1}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Address Line 2"
                  req={true}
                  name="address2"
                  value={names.address2}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div>
              <Checkbox
                name="display"
                note="Check above to indicate whether or not to display address on donation page."
                update={handleUpdate}
                data={[
                  {
                    value: false,
                    label: "Check to display charity address.",
                  },
                ].map(item => {
                  return {
                    value: item.value,
                    label: item.label,
                    checked: names.display,
                  }
                })}
              />
            </div>

            <p className="line" />

            <div className="three">
              <div>
                <Input
                  type="text"
                  label="Contact First Name"
                  req={true}
                  name="cfname"
                  value={names.cfname}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Contact Last Name"
                  req={true}
                  name="clname"
                  value={names.clname}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Contact Email"
                  req={true}
                  name="email"
                  value={names.email}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div className="three">
              <div>
                <Input
                  type="text"
                  label="Finance First Name"
                  req={true}
                  name="cfname2"
                  value={names.cfname2}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Finance Last Name"
                  req={true}
                  name="clname2"
                  value={names.clname2}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Finance Email"
                  req={true}
                  name="email2"
                  value={names.email2}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <p className="line" />

            <div className="three">
              <div className="small">
                <Input
                  type="text"
                  label="Phone"
                  req={true}
                  name="phone"
                  value={names.phone}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div className="small">
                <Input
                  type="text"
                  label="Fax"
                  req={false}
                  name="fax"
                  value={names.fax}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="text"
                  label="Web Site"
                  req={false}
                  name="web"
                  value={names.web}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            <div className="two">
              <div>
                <Textarea
                  label="Mission Statement"
                  req={true}
                  name="mission"
                  value={names.mission}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div>
                <Input
                  type="text"
                  label="Children Supported"
                  req={true}
                  name="children"
                  value={names.children}
                  update={handleUpdate}
                  errors={errors}
                  note="Number of children organization supports on an annual basis (must be a number)."
                />
              </div>
            </div>

            <p className="line" />

            <div className="one">
              <InputFile
                label="Charity Logo"
                req={false}
                name="file"
                value={names.file}
                update={handleUpdate}
                errors={errors}
                note="Upload a logo to be included on the BFC donation page (JPG or PNG)."
              />
            </div>

            {names.newLogo && (
              <Logo className="one">
                <img src={names.newLogo} alt="Logo" />
              </Logo>
            )}

            {msg.type && <Msg data={msg} />}

            {msg.type !== "working" && <Submit label="Update Details" />}
          </form>
        </>
      )}
    </Container>
  )
}

export default Manage
