import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 5px;
    cursor: pointer;
  }

  & label.hidden {
    display: none;
  }

  & label > span.error {
    color: var(--error);
  }

  & input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  & small {
    padding-top: 5px;
    line-height: 18px;
  }

  & small.hidden {
    display: none;
  }
`
