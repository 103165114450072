import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > div {
    margin-top: 0;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  & > div > label {
    display: block;
    cursor: pointer;

    margin-left: 0;
    margin-bottom: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
  }

  & > div > label:hover span,
  & > div > label:hover svg {
    color: var(--blue);
  }

  & > div > label > span {
    padding-left: 7.5px;
  }

  & small {
    padding-top: 5px;
    line-height: 18px;
  }

  & small.hidden {
    display: none;
  }
`
